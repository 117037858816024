<template>
  <div class="card" style="margin: 10px;">
    <div class="card-content">
      <div class="columns">
        <div class="column is-four-fifths" style="font-weight: bold; margin-left: 10px">Monthly Duty Free Claim Request - Customers
        </div>
        
      </div>
    </div>
    <div class="card-content">
      <vue-apex-charts ref="barChart" type="bar" width="480" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
  </div>
</template>
  
<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

const today = new Date()

export default {
  name: "WeeklyClaimAmountBarChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
     
      series: [{
        name: 'DF Requests',
        data: []
      }],
      chartOptions: {
        chart: {
          width: 480,
          type: 'bar',
          toolbar: {
        show: false
        },
        },
        colors: ['#AD51B2'],
        xaxis: {
          categories: [],
          title: {
            text: 'Months'
          }
        },
        dataLabels: {
              enabled: false // set this to false to remove the data labels
            },
            
        yaxis: {
          min: 0,
          forceNiceScale: true,
          title: {
            text: 'Claim Requests',
          },
          labels: {
            formatter: function (val) {
              const parsedValue = parseInt(val);
              return isFinite(parsedValue) ? parsedValue.toString() : ' ';
              // return isFinite(parsedValue) ? parsedValue.toString() : null;
            }

          }
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return parseInt(val).toString();
            }
          }
        }
      },
    }
  },

  methods: {
    loadData: function () {
      const self = this
      NetworkManager.apiRequest('api/DFDashboard/get-total-requests-by-month', {}, function (e) {
        if (e.statusCode === 200) {
          self.series = e.data.transactions
          self.$refs.barChart.updateOptions({
            xaxis: {
              categories: e.data.monthNames
            }
          })
        }
        else {
          //
        }
      })


    }
  },

  mounted() {
    this.loadData()
  }
}
</script>
  
<style scoped></style>
  