<template>
  <div class="card" style="margin: 10px;  min-height: 300px">
    <div class="card-content">
      <div class="columns">
        <div class="column is-half" style="font-weight: bold; margin-left: 10px">Duty Free Requests-Customers</div>
        <div class="column is-narrow is-flex is-align-items-center" style="justify-content: flex-end;">
          <b-field>
            <b-datepicker @input="loadData" v-model="dateRange" :mobile-native="false" range>
              <template v-slot:trigger>
                <div style="display: flex; align-items: center;">
                  <span style="color:blueviolet; margin-right: 5px;"><b>Custom</b></span>
                  <b-icon icon="calendar-month-outline" custom-color="blueviolet" aria-hidden="true"
                    aria-label="Calendar icon" style="color:blueviolet;" />
                </div>
              </template>
            </b-datepicker>
          </b-field>
        </div>
        <div class="column is-narrow">
          <b-dropdown @change="loadData" class="is-pulled-right" v-model="selectedFilter"
            aria-role="list">
            <template #trigger>
              <b-button type="is-primary" size="is-small" icon-right="menu-down" outlined
                style="border: none; box-shadow: none; font-size: 14px;">
                <b>{{ selectedFilter.text }}</b>
              </b-button>
            </template>
            <b-dropdown-item v-for="item in filters" :value="item" aria-role="listitem">
              <span>{{ item.text }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="card-content">
        <vue-apex-charts ref="pieChart" type="pie" width="450" :options="chartOptions" :series="series"></vue-apex-charts>
      </div>
    </div>

  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import NetworkManager from "@/network";

const today = new Date()

export default {
  name: "RangeOfDutyFreePieChart",
  components: {
    VueApexCharts: VueApexCharts,
  },
  data() {
    return {
      dateRange: [today, today],
      selectedFilter: { value: 365, text: 'Yearly' },
      filters: [
        { value: 7, text: 'This Week' },
        { value: 14, text: 'Last Two Weeks' },
        { value: 30, text: 'Last Month' },
        { value: 90, text: 'Last Three Months' },
        { value: 365, text: 'Yearly' },
        { value: 0, text: 'Custom' },
      ],
      requestData:{},
      series: [],
      chartOptions: {
        chart: {
          width: 450,
          height: 500,
          type: 'pie',
        },
        labels: ['Responded Requests', 'Pending Requests'],
        legend: {
          position: 'left',
        },
        title: {
          text: '',
          align: 'left'
        },
        colors:['#CA498C','#CF9BBD','#B977AC'],
        responsive: [{
          breakpoint: 380,
          options: {
            chart: {
              width: 200
            },
            legend: {
              position: 'bottom'
            },
          }
        }]
      },
    }
  },

  methods: {
    formatDate: function (date) {
      let d = new Date(date),
          month = '' + (d.getMonth() + 1),
          day = '' + d.getDate(),
          year = d.getFullYear();

      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;

      return [year, month, day].join('-');
    },

    loadData: function (){
      const self = this

      setTimeout(() => {
        const data = {
          range: self.selectedFilter.value,
          fromDate: self.formatDate(self.dateRange[0]),
          toDate: self.formatDate(self.dateRange[1])
        }

        NetworkManager.apiRequest('api/DFDashboard/count-dutyfree-requests', data, function (e){
          if(e.statusCode === 200){
            self.series = Object.values(e.data)
            console.log(series)
          }
          else {
            //
          }
          
        })
      }, 200)
    }
  },

  mounted() {
    this.loadData()
  }
}
</script>

<style scoped>

</style>
