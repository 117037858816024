<template>
  <div class="test">
    <section>
      <div class="card-header p-3">
        <div class="columns" style="width: 100%">
          <div class="column">
            <b-breadcrumb align="is-left">
              <b-breadcrumb-item tag="router-link" to="" active>Duty free Merchant</b-breadcrumb-item>
            </b-breadcrumb>
          </div>
        </div>
      </div>
      <section class="mt-5 mr-6" style="height: 73vh; overflow: auto">
        <b-field horizontal label="Name">
          <b-input v-model="user_request.name" expanded readonly></b-input>
        </b-field>
        <b-field horizontal label="NIC Number">
          <b-input v-model="user_request.nic_number" expanded readonly></b-input>
        </b-field>
        <b-field horizontal label="Current passport">
          <b-input v-model="user_request.current_passport" expanded readonly></b-input>
        </b-field>
        <b-field horizontal label="Date">
          <b-input v-model="user_request.date" expanded readonly></b-input>
        </b-field>
        <b-field horizontal label="Product(s)" required>
          <b-input v-model="user_request.products" expanded required maxlength="50"></b-input>
        </b-field>
        <b-field horizontal label="No. of Item Purchases">
          <b-field>
            <b-input v-model="user_request.no_of_purchases" type="number" min="0" max="500" expanded required>
            </b-input>
          </b-field>
        </b-field>
       <b-field horizontal label="Reference">
          <b-input v-model="user_request.reference" expanded required maxlength="30"></b-input>
        </b-field>
        <b-field horizontal label="Remark">
          <b-input v-model="user_request.remark" expanded required maxlength="30"></b-input>
        </b-field>
        <b-field horizontal label="Invoice Number">
          <b-input v-model="user_request.invoice_number" maxlength="30" expanded required></b-input>
        </b-field>
        <b-field horizontal label="Invoice Amount (USD)">
          <b-input v-model="user_request.invoice_amount" maxlength="20" expanded required></b-input>
        </b-field>
        <b-field horizontal label="Allowance Claim Amount (USD)">
          <b-input v-model="user_request.claimed_amount" maxlength="20" expanded required></b-input>
        </b-field>
      </section>
      <b-card-footer>
        <div class="columns">
          <div class="column is-flex is-justify-content-flex-end mr-6">
            <b-button type="is-danger ml-2" @click="goBack">Cancel</b-button>
            <b-button type="is-success ml-2" @click="validateAndUploadData()">Save</b-button>
          </div>
        </div>
      </b-card-footer>
    </section>
    <b-modal has-modal-card :can-cancel="false" trap-focus v-model="showErrorPopup" title="Error">
      <div class="modal-card" style="height: 300px; width: 500px">
        <header class="modal-card-head">
          <p class="modal-card-title" style="
              display: flex;
              justify-content: center;
              font-size: 16px;
              font-weight: bold;
            ">
            {{ errorMessage }}
          </p>
        </header>
        <section class="modal-card-body" style="display: flex; justify-content: center; align-items: center">
          <!-- <b-icon icon="close-circle" type="is-danger" size="is-large">
          </b-icon> -->
          <div>
            <img v-if="type === 'is-success'" src="@/assets/check-circle.png" alt="Success Icon" class="custom-icon" />

            <img v-else src="@/assets/close-circle.png" alt="Error Icon" />
          </div>
        </section>
        <footer class="modal-card-foot" style="display: flex; justify-content: center">
          <b-button type="is-primary" @click="hideErrorPopup">Done</b-button>
        </footer>
      </div>
    </b-modal>

    <pop-up-message :is_active="popUpData.active" :type="popUpData.type" :message="popUpData.message"></pop-up-message>
  </div>
</template>

<script>
import moment from "moment";
import NetworkManager from "@/network";
import PopUpMessage from "@/views/DutyFreeMerchant/Components/PopUpMessage";

export default {
  // props: {
  //     user_request: {
  //         type: Object,
  //         default: () => ({
  //             name: '',
  //             nic_number: '',
  //             current_passport: '',
  //             date: ''
  //         })
  //     }
  // },
  components: {
    PopUpMessage,
  },
  data() {
    return {
      popUpData: {
        active: false,
        type: "is-success",
        message: "",
      },
      user_request: {
        name: "",
        dutyfree_amount: "",
        nic_number: "",
        current_passport: "",
        date: "",
        df_request_id: "",
        products: "",
        no_of_purchases: "",
        reference: "",
        remark: "",
        invoice_number: "",
        invoice_amount: "",
        claimed_amount: "",
      },
      showErrorPopup: false,
      errorMessage: "",
    };
  },
  computed: {
    isDesktop() {
      return window.innerWidth > 768;
    },
  },
  mounted() {
    console.log(this.$route.query);
    // Prefill form fields with data from DutyFreeMerchant page
    this.user_request.dutyfree_amount = this.$route.query.dutyfree_amount;
    this.user_request.name = this.$route.query.name;
    this.user_request.nic_number = this.$route.query.nic_number;
    this.user_request.df_request_id = this.$route.query.id;
    this.user_request.current_passport = this.$route.query.current_passport;
    // Parse the date string to a Moment.js object
    const date = moment(this.$route.query.date);
    // Format the date to desired format (e.g. "YYYY-MM-DD")
    this.user_request.date = date.format("YYYY-MM-DD");
  },
  methods: {
    validateAndUploadData() {
      if (
        !this.user_request.name ||
        !this.user_request.nic_number ||
        !this.user_request.current_passport ||
        !this.user_request.date ||
        !this.user_request.products ||
        !this.user_request.no_of_purchases ||
        !this.user_request.reference ||
        !this.user_request.remark ||
        !this.user_request.invoice_number ||
        !this.user_request.invoice_amount ||
        !this.user_request.claimed_amount
      ) {
        this.errorMessage = "All fields are required.";
        this.$buefy.toast.open({
          message: "All fields are required.",
          type: "is-danger",
          duration: 1000,
          position: "is-top",
        });
        return;
      }
      this.uploadData();
    },
    uploadData: function () {
      const self = this;
      if (
        parseFloat(self.user_request.invoice_amount) <
        parseFloat(self.user_request.claimed_amount)
      ) {
        self.errorMessage =
          "Claimed amount can not be greater than invoice amount";
        self.showErrorPopup = true;
        return;
      }
      if (
        parseFloat(self.user_request.claimed_amount) >
        parseFloat(self.user_request.dutyfree_amount)
      ) {
        self.errorMessage =
          "Claimed amount can not be greater than Duty-Free amount";
        self.showErrorPopup = true;
        return;
      } else {
        self.errorMessage = "";
        self.showErrorPopup = false;
      }

      self.buttonsLoading = true;

      const data = {
        df_request_id: self.user_request.df_request_id,
        products: self.user_request.products,
        no_of_purchases: self.user_request.no_of_purchases,
        reference: self.user_request.reference,
        remark: self.user_request.remark,
        invoice_number: self.user_request.invoice_number,
        invoice_amount: self.user_request.invoice_amount,
        claimed_amount: self.user_request.claimed_amount,
      };
      console.log(data);
      NetworkManager.apiRequest(
        "api/Dutyfree/DFRequest/add-purchase",
        data,
        function (e) {
          if (e.statusCode === 200) {
            self.popUpData.type = "is-success";
            self.popUpData.message = "Transaction data uploaded successfully";
            self.popUpData.active = true;
          } else {
            self.popUpData.type = "is-danger";
            self.popUpData.message = "Transaction data upload failed";
            self.popUpData.active = true;
          }
        }
      );
    },
    hideErrorPopup() {
      this.showErrorPopup = false;
    },
    goBack() {
      window.history.back();
    },
  },
};
</script>

<style scoped>
/* ... existing styles */
@media screen and (max-width: 768px) {
  .test {
    width: 100%;
    margin-left: 10px;
  }
}

.b-numberinput input {
  text-align: left !important;
}
</style>